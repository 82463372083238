%ff-regular {
  font-family: 'Post Grotesk', sans-serif;
}

%ff-symbols {
  font-family: 'Montessori Symbols';
}

%fw-bold {
  font-weight: 700;
}

%fs-base {
  font-size: $fs-base;
  line-height: $lh-base;

  @media (min-width: 1680px) {
    font-size: 0.6vw;
  }
}

%fs-regular {
  font-size: 1.7rem;

  @media (min-width: $large) {
    // font-size: 1.7rem;
  }

  @media (min-width: $xlarge) {
    font-size: 2.1rem;
  }
}

%fs-small {
  font-size: 1.2rem;

  @media (min-width: $large) {
    font-size: 1.4rem;
  }

  @media (min-width: $xlarge) {
    // font-size: 1.6rem;
  }
}

%fs-references {
  // font-size: 1.2rem;

  @media (min-width: $large) {
    font-size: 1.4rem;
  }

  @media (min-width: $xlarge) {
    font-size: 1.6rem;
  }
}

%fs-site-title {
  font-size: 2.9rem;
  line-height: 1.05;

  @media (min-width: $large) {
    font-size: 3.5rem;
  }

  @media (min-width: $xlarge) {
    // font-size: 4.2rem;
  }

  @media (min-width: $xxlarge) {
    // font-size: 3.5rem;
  }
}

%fs-page-title {
  font-size: 2.9rem;
  line-height: 1.05;

  @media (min-width: $large) {
    font-size: 6.5rem;
  }

  @media (min-width: $xlarge) {
    font-size: 8.5rem;
    // line-height: 1;
  }
}

%fs-column-title {
  // font-size: 1.7rem;
  // line-height: 1.05;
  font-size: 2.1rem;
  line-height: 1.15;

  @media (min-width: $large) {
    // font-size: 2.1rem;
    font-size: 2.9rem;
    // line-height: 1.24;
  }

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
  }
}

%fs-large-body {
  @extend %fs-column-title;
}

%fs-tool-body {
  font-size: 2.1rem;
  line-height: 1.15;

  @media (min-width: $large) {
    // font-size: 2.1rem;
    font-size: 2.9rem;
    // line-height: 1.24;
  }

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
    line-height: 1.08;
  }
}

%fs-filter-bar,
%fs-filter-bar-icon,
%fs-slider-icon,
%fs-sub-nav {
  @extend %fs-tool-body;
}

%fs-filter-nav {
  font-size: 2.9rem;
  line-height: 1.24;
}

%fs-filter-actions {
  font-size: 2.9rem;
  line-height: 1.24;

  @media (min-width: $large) {
    font-size: 2.1rem;
  }

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
    line-height: 1.08;
  }
}

%fs-tool-nav {
  font-size: 2.9rem;
  line-height: 1.24;

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
    line-height: 1.08;
  }
}

%fs-pattern-title {
  font-size: 2.9rem;
  line-height: 1.05;

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
  }
}

%fs-pattern-column-title {
  font-size: 1.7rem;
  line-height: 1.05;

  @media (min-width: $large) {
    font-size: 2.9rem;
  }

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
  }
}

%fs-tool-bar {
  // font-size: 2.9rem;
  // line-height: 1.05;

  @media (min-width: $large) {
    font-size: 2.1rem;
  }

  @media (min-width: $xlarge) {
    // font-size: 3.5rem;
  }
}

%fs-tool-bar-icon {
  font-size: 2.9rem;
  line-height: 1.05;

  @media (min-width: $large) {
    // font-size: 2.1rem;
  }

  @media (min-width: $xlarge) {
    // font-size: 3.5rem;
  }
}

%fs-filter {
  font-size: 1.8rem;

  @media (min-width: $large) {
    font-size: 2.1rem;
  }

  @media (min-width: $xlarge) {
    font-size: 3.5rem;
  }
}
