
          @import '@/styles/globals.scss';
        






































.item {
  // @include page-gutter(padding, top);
  margin-bottom: $blank-line;
}

.image {
  margin-bottom: $gutter / 4;
  filter: grayscale(1);
  transition: filter 150ms ease;

  @media #{$mqHover} {
    &:hover {
      filter: grayscale(0);
    }
  }

  @media (min-width: $large) {
    margin-bottom: $gutter / 2;
  }
}

// .title {}
