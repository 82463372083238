
          @import '@/styles/globals.scss';
        












































.component {
  display: flex;
  margin-left: -$gutter;
  margin-bottom: $blank-line / 2;

  > * {
    padding-left: $gutter;
  }
}

.paging {
  margin-right: auto;
}
