
          @import '@/styles/globals.scss';
        






























































.category {
  &:not(:first-of-type) {
    @include border-regular(top);

    margin-top: $blank-line * 1.5;

    .category-title {
      margin-top: $blank-line / 3;
    }
  }
}

.category-title {
  @extend %fs-pattern-title;

  margin-bottom: $blank-line / 3;
}

.list {
  // position: relative;
  // top: -$gutter;

  // @media (min-width: $large) {
  //   top: -$gutter-large;
  // }

  @media (min-width: $medium) {
    display: flex;
    flex-wrap: wrap;
    margin-left: $gutter * -2;

    > * {
      width: 50%;
      padding-left: $gutter * 2;
    }
  }

  // @media (min-width: $xxlarge) {
  //   > * {
  //     width: 33.333%;
  //   }
  // }
}

// .item {
//   &:last-of-type {
//     min-height: 100vh;
//   }
// }
