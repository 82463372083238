@mixin input-reset {
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
  background-color: transparent;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;

  &::placeholder {
    color: inherit;
  }
}

//Replace default <input> (checkbox, radio) with icon. Apply mixin on parent <label>
@mixin input-icon($unchecked: '\e000', $checked: '\e001', $indent: 1.5em) {
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    ~ span {
      position: relative; // Needed for FF to place icons correctly when checked
      display: inline-block;
      padding-left: $indent;
      user-select: none;
      -webkit-touch-callout: none;

      &::before {
        @extend %ff-symbols;

        position: absolute;
        top: 0.05em; // Optically center icon with label
        margin-left: -$indent;
        content: $unchecked;
      }
    }

    &:checked ~ span::before {
      content: $checked;
    }
  }

  /*
  @media #{$mqHover} {
    &:hover {
      input ~ span:before {
        content: $checked;
      }
    }
  }
  */
}

@mixin button-reset {
  @include input-reset;

  cursor: pointer;
  user-select: none;

  &[disabled] {
    cursor: default;
  }
}

@mixin border-regular($direction: null, $color: $grey-border) {
  @if $direction {
    border-#{$direction}: 1px solid $color;
  } @else {
    border: 1px solid $color;
  }
}

@mixin page-gutter($property: padding, $directions...) {
  @if length($directions) > 0 {
    @each $direction in $directions {
      #{$property}-#{$direction}: $gutter;
      @media (min-width: $large) {
        #{$property}-#{$direction}: $gutter-large;
      }
    }
  } @else {
    #{$property}: $gutter;
    @media (min-width: $large) {
      #{$property}: $gutter-large;
    }
  }
}

@mixin page-gutter-extended($property: padding, $directions...) {
  @if length($directions) > 0 {
    @each $direction in $directions {
      //#{$property}-#{$direction}: $gutter;
      @media (min-width: $xxlarge) {
        #{$property}-#{$direction}: $gutter-large * 2;
      }
    }
  } @else {
    //#{$property}: $gutter;
    @media (min-width: $xxlarge) {
      #{$property}: $gutter-large * 2;
    }
  }
}

@mixin page-gutter-vertical($property: padding, $directions...) {
  @if length($directions) > 0 {
    @each $direction in $directions {
      #{$property}-#{$direction}: $gutter / 1.1;
      @media (min-width: $large) {
        #{$property}-#{$direction}: $gutter * 1.9;
      }
    }
  } @else {
    #{$property}: $gutter / 1.1;
    @media (min-width: $large) {
      #{$property}: $gutter * 1.9;
    }
  }
}

%view-padding {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);
}

%header-margin {
  //@include page-gutter(margin, bottom);
  margin-bottom: $gutter * 1.5;

  @media (min-width: $large) {
    min-height: $blank-line * 5.5;
  }
}

%box-padding {
  padding: 0.3em 0.5em;
}

%body-field {
  h3 {
    @extend %fw-bold;
  }

  a {
    @extend %hover-link;
  }

  p,
  ul {
    margin-bottom: $blank-line;
  }

  :global(.small) {
    @extend %fs-small;
  }

  :global(.footnote) {
    @extend %fs-small;
  }

  :global(.button) {
    display: inline-block;
    padding: 0.35em 0.5em;
    border: 1px solid;

    &::after {
      @extend %ff-symbols;

      padding-left: 0.25em;
      content: '\e015';
    }
  }
}

%default-link {
  color: $grey-text;
}

%hover-link {
  color: $grey-text;
  transition: color 100ms ease;

  &:hover {
    color: $black;
  }
}
