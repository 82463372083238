
          @import '@/styles/globals.scss';
        









































.component {
  &:not(:last-child) {
    margin-right: 0.7em;
  }
}

.link {
  // @extend %hover-link;
  white-space: nowrap;
  color: $grey-fill;

  @media (min-width: $medium) {
    color: $grey-text;
    transition: color 100ms ease;

    &:hover {
      color: $black;
    }

    &.active {
      color: $black;
      cursor: default;
    }
  }
}
