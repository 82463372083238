
          @import '@/styles/globals.scss';
        













































































// .component {}

.logos {
  display: flex;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }

  .ami {
    flex: 1;
  }

  .awf {
    flex: 2.6;
    margin-top: 0.1em;
  }
}
