
          @import '@/styles/globals.scss';
        































.header {
  @extend %header-margin;

  display: none;

  @media (min-width: $large) {
    display: block;
  }
}

.title,
.subtitle {
  @extend %fs-page-title;
}

.nav {
  // @extend %header-margin;
  // margin-bottom: $blank-line * 1.5;
}

// .children {
//   @include border-regular(top);
//   padding-top: $gutter / 2;

//   @media (min-width: $large) {
//     padding-top: $gutter-large / 2;
//   }
// }
