@import '../node_modules/normalize.css/normalize.css';
@import 'styles/_variables.css';

@font-face {
  font-family: 'Post Grotesk';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/PostGrotesk-Book.woff2') format('woff2'),
    url('./assets/fonts/PostGrotesk-Book.woff') format('woff');
}

@font-face {
  font-family: 'Post Grotesk';
  font-style: italic;
  font-weight: 400;
  src: url('./assets/fonts/PostGrotesk-BookItalic.woff2') format('woff2'),
    url('./assets/fonts/PostGrotesk-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'Post Grotesk';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/PostGrotesk-Bold.woff2') format('woff2'),
    url('./assets/fonts/PostGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Post Grotesk';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/PostGrotesk-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/PostGrotesk-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montessori Symbols';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/MontessoriSymbols-Regular.woff2') format('woff2'),
    url('./assets/fonts/MontessoriSymbols-Regular.woff') format('woff');
}

html {
  @extend %fs-base;
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @extend %ff-regular;
  @extend %fs-regular;
  height: 100%;
  color: $black;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

em {
  font-style: italic;
}

strong {
  font-weight: inherit; // NOTE: No bold font weight provided
}

img {
  display: block;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font: inherit;
}

p {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

button {
  @include button-reset;
}

input {
  @include input-reset;
}
