
          @import '@/styles/globals.scss';
        




































































.component {
  opacity: 0;
  transition: opacity 350ms ease;

  &:global(.is-loaded) {
    opacity: 1;
  }
}
