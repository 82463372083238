
          @import '@/styles/globals.scss';
        





























.component {
  @include border-regular(top);
  padding-top: $blank-line / 2;
}

.title {
  @extend %fs-column-title;
  margin-bottom: $blank-line / 2;
}

.list {
  @media (min-width: $medium) {
    display: flex;
    margin-left: $gutter * -1.5;

    > * {
      padding-left: $gutter * 1.5;
    }
  }
}
