
          @import '@/styles/globals.scss';
        





































.component {
  margin-bottom: $blank-line;

  @media (min-width: $large) {
    margin-bottom: $blank-line * 2;
  }
}

.item {
  &:not(:last-child) {
    margin-bottom: $blank-line;
  }
}

.caption {
  @extend %fs-references;

  margin-top: 0.25em;
}
