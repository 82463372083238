
          @import '@/styles/globals.scss';
        





















































.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);
  // position: relative;
  // z-index: 2;
  // margin-top: var(--panel-bar-height);
  // min-height: 100%;
  // background-color: $white;

  // @media (min-width: $large) {
  //   margin-top: 0;
  //   margin-left: var(--panel-bar-height);
  // }
}

// .header {
//   @extend %header-margin;
//   display: flex;
//   justify-content: space-between;
// }

// .title {
//   @extend %fs-page-title;
// }

.content {
  margin-top: $blank-line;
}
