:root {
  --panel-bar-height: 3.6rem;
  --active-panel-bar-height: 5.5rem;
  --tool-panel-bar-height: 6.6rem;
  --tool-active-panel-bar-height: 8.7rem;
  --slider-actions-height: var(--active-panel-bar-height);
}

@media (min-width: 64em) {
  :root {
    --tool-panel-bar-height: 7.3rem;
  }
}
