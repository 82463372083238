// Colors
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);
$grey-fill: rgb(230, 230, 230);
$grey-border: rgb(145, 145, 145);
$grey-text: rgb(160, 160, 160);
$blue: rgb(100, 130, 255);
$blue-shadow: rgb(200, 200, 200);
$red: rgb(255, 88, 88);
$red-shadow: rgb(210, 210, 210);
$yellow: rgb(255, 252, 0);
$yellow-shadow: rgb(160, 160, 160);

// Breakpoints
$xxsmall: 20em; // 320px
$xsmall: 30em; // 480px
$small: 40em; // 640px
$medium: 48em; // 768px
$large: 64em; // 1024px
$xlarge: 80em; // 1280px
$xxlarge: 90em; // 1440px

// Media queries
$mqHover: '(hover: hover) and (pointer: fine)';

// Typography
$fs-base: 0.625em; // 10px
$lh-base: 1.175;

// Spacing
$blank-line: $lh-base * 1em;
$gutter: 1.5rem;
$gutter-large: $gutter * 2.33334;
$page-indent: 28vw;
$text-width: 32em;

// Easing
$swift-out: cubic-bezier(0.55, 0, 0.1, 1);

// Shadows
$panel-shadow: 0 0 0.5em 0 rgba($black, 0.5);
$page-shadow: $panel-shadow;
