
          @import '@/styles/globals.scss';
        





































.component {
  @media (min-width: $small) {
    &:not(:first-child) {
      @include border-regular(top);
      margin-top: $blank-line * 0.5;
      padding-top: $blank-line * 1.5;
    }
  }
}
