
          @import '@/styles/globals.scss';
        



























.component {
  // &:not(:last-child) {
  //   margin-bottom: $blank-line * 3;
  // }

  // // &.count-1 {}
  // // &.count-2 {}
  // // &.count-3 {}

  // // &.extend-first {}
  // // &.extend-last {}

  // > *:not(:last-child) {
  //   margin-bottom: $blank-line * 2;
  // }

  @media (min-width: $small) {
    // &:not(:last-child) {
    //   margin-bottom: $blank-line * 2;
    // }

    // > *:not(:last-child) {
    //   margin-bottom: 0;
    // }

    &:global(.count-2),
    &:global(.count-3) {
      display: flex;
      margin-left: $gutter * -1.5;

      > * {
        padding-left: $gutter * 1.5;
      }
    }

    &:global(.count-2) {
      > * {
        width: 50%;
      }
    }

    &:global(.count-3) {
      > * {
        width: 33.3333%;
      }
    }
  }

  // @media (min-width: $medium) {
  //   // TODO: Should .count-3 only start to flex from here (as it does for the stores-list)
  // }

  @media (min-width: $large) {
    // &:not(:last-of-type) {
    //   margin-bottom: $blank-line * 3;
    // }

    &:global(.count-2) {
      &:global(.extend-first) {
        > * {
          width: 33.3333%;

          &:first-child {
            width: 66.6666%;
          }
        }
      }

      &:global(.extend-last) {
        > * {
          width: 66.6666%;

          &:first-child {
            width: 33.3333%;
          }
        }
      }
    }
  }
}
