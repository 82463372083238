
          @import '@/styles/globals.scss';
        






























































.category {
  padding-top: $gutter / 2;
  // @include border-regular(top);
  margin-bottom: $gutter;

  @media (min-width: $large) {
    display: flex;
    padding-top: 0;
    margin-bottom: 0;

    &:first-child {
      > * {
        padding-top: 0;
        border-top: 0;

        > :first-child {
          padding-top: 0;
          border-top: 0;
        }
      }
    }
  }
}

.category-title {
  @extend %fs-column-title;

  margin-bottom: 0.5em;

  @media (min-width: $large) {
    @include border-regular(top);

    padding-top: 0.4em;
    margin-right: 2.7rem;
    margin-bottom: 0;
  }
}

.category-title,
.list {
  @media (min-width: $large) {
    width: 50%;
  }
}
