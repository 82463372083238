
          @import '@/styles/globals.scss';
        













































































































































































































































































































$duration: 225ms;
$delay: $duration * 2;

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &:global(.panels-closed) {
    > * {
      &.home {
        flex-grow: 1;
      }

      > a {
        // display: block;
        flex: 1;
        height: 100%;
      }
    }

    .view {
      pointer-events: none;
    }

    .header,
    .shape {
      visibility: visible;
      opacity: 1;
      transition: opacity $duration ease $delay,
        visibility $duration ease $delay;
    }

    .tool {
      background-color: $yellow;
    }
  }

  &:global(.panel-expanded) {
    // .header {
    //   opacity: 0;
    //   visibility: hidden;
    //   transition: opacity $duration ease, visibility $duration ease;
    // }

    > * {
      .subtitle {
        display: none;
      }
    }
  }

  > * {
    position: relative;
    display: flex;
    // flex-basis: 100%;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    // transition: flex-grow $delay ease $delay, background-color 150ms ease;
    // NOTE: Use "all" instead of "flex-grow" as Safari 13.1 doesn’t play the flex transition otherwise
    transition: all $delay ease $delay, background-color 150ms ease;

    &:global(.is-active) {
      flex-grow: 1;
    }

    > a {
      position: relative;
      // z-index: 1;
      display: block;
      min-height: var(--panel-bar-height);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &.tool {
      > a {
        min-height: var(--tool-panel-bar-height);
      }
    }
  }

  .portal {
    position: absolute;
  }

  @media (min-width: $large) {
    flex-direction: row;

    > * {
      > a {
        min-width: var(--panel-bar-height);
        min-height: 0;
      }

      &.tool {
        > a {
          min-width: var(--tool-panel-bar-height);
          min-height: 0;
          transition: min-width $duration ease $delay;
        }
      }
    }

    &:global(.panels-closed) {
      > * {
        &:hover {
          // flex-grow: 1.1; // TODO: If activated, it causes problems when closing the panels as the mouse is hovering over a panel right away
        }
      }
    }

    &:global(.panel-expanded) {
      > * {
        // TODO: removing the delay from flex-grow breaks transition
        // transition: flex-grow $delay ease, background-color 150ms ease;
        // NOTE: Use "all" instead of "flex-grow" as Safari 13.1 doesn’t play the flex transition otherwise
        transition: all $delay ease, background-color 150ms ease;
        // transition: flex-grow $delay ease $delay, background-color 150ms ease;

        > a {
          width: var(--panel-bar-height);
          height: 100%;
        }

        &:global(.is-active) {
          > a {
            width: var(--active-panel-bar-height);
          }
        }

        &:global(.not-active) {
          > a {
            // Override min-width of .tool > a
            min-width: var(--panel-bar-height);
            min-height: 0;
            transition: min-width $duration ease;
          }

          // .header {
          //   position: absolute;
          //   bottom: 0;
          //   left: 0.15em;
          //   padding: 0;
          //   visibility: visible;
          //   opacity: 1;
          //   transform: rotate(-90deg);
          //   transform-origin: top left;

          //   h1,
          //   h2 {
          //     white-space: nowrap;
          //   }
          // }

          // &:hover {
          //   flex-grow: 0.01;
          // }
        }
      }

      .claim {
        opacity: 0;
        transition: opacity $duration ease;
      }
    }
  }

  @media (min-width: $xxlarge) {
    &:global(.panel-expanded) {
      > * {
        &:global(.is-active) {
          > a {
            // width: $gutter-large * 2; // TODO: this adds sudden jump when switching panels
          }
        }
      }
    }
  }
}

.panel {
  overflow: hidden;
  background-color: $grey-fill;
  box-shadow: $panel-shadow;

  &:global(.is-active) {
    // .header {
    //   opacity: 1;
    //   visibility: visible;
    //   transition: opacity $duration ease $delay,
    //     visibility $duration ease $delay;
    // }
  }

  &.tool {
    ::selection {
      background-color: rgba($yellow, 0.99);
    }
  }

  &.patterns {
    ::selection {
      background-color: rgba($blue, 0.99);
    }
  }

  &.projects {
    ::selection {
      background-color: rgba($red, 0.99);
    }
  }

  &:global(.is-active) {
    &.tool {
      background-color: $yellow;
    }

    &.patterns {
      background-color: $blue;
    }

    &.projects {
      background-color: $red;
    }
  }

  @media #{$mqHover} {
    &:hover {
      &.tool {
        background-color: $yellow;

        .subtitle {
          color: $yellow-shadow;
        }
      }

      &.patterns {
        background-color: $blue;

        .subtitle {
          color: $blue-shadow;
        }
      }

      &.projects {
        background-color: $red;

        .subtitle {
          color: $red-shadow;
        }
      }
    }
  }

  // > a {}
}

.cover {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.preorder {
  position: absolute;
  top: 0;
  right: 0;
  width: 15rem;
  height: auto;

  @media (min-width: $large) {
    top: $gutter;
    right: $gutter * 2;
    width: 19rem;
  }
}

.logos {
  display: none;

  @media (min-width: $large) {
    position: absolute;
    bottom: $gutter * 2;
    left: $gutter * 2;
    display: block;
    width: 30rem;
  }
}

// .header,
.shape {
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration ease, visibility $duration ease;
}

.header {
  @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, top, bottom);

  position: absolute;
  padding-top: $gutter / 1.1;
  padding-bottom: $gutter / 1.1;
  // transition: opacity $duration ease $delay, visibility $duration ease $delay;
  // transition: opacity $duration ease, visibility $duration ease;

  @media (min-width: $large) {
    // position: absolute;
    bottom: 0;
    left: 0.15em;
    padding-right: 0;
    // padding: 0;
    padding-left: 0;
    visibility: hidden;
    visibility: visible;
    opacity: 0;
    opacity: 1;
    transform: rotate(-90deg);
    transform-origin: top left;

    h1,
    h2 {
      position: relative;
      top: $gutter / -1.1 / 2;
      white-space: nowrap;
    }

    h1 {
      // Move title "down" as it consists of two lines
      left: calc((#{$gutter} / 1.1 * -2) - 1.05em);
    }

    h2 {
      left: $gutter / 1.1 * -2;
    }
  }
}

.claim {
  transition: opacity $duration ease $delay;
}

.title,
.subtitle {
  @extend %fs-site-title;
}

.subtitle {
  color: $grey-text;
  transition: color 150ms ease;
}

.shape {
  // svg
  @include page-gutter(padding, right, bottom, left);

  position: absolute;
  top: 7em;
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
  // opacity: 0;
  // visibility: hidden;
  // transition: opacity $duration ease, visibility $duration ease;

  @media (min-width: $large) {
    top: 8.5em;
    width: auto;
    height: calc(100% - 8.5em);
  }

  @media (min-width: $xlarge) {
    top: 7em;
    height: calc(100% - 7em);
  }

  @media (min-width: $xxlarge) {
    top: 9em;
    height: calc(100% - 9em);
  }
}

.triangle,
.circle,
.rectangle {
  fill: transparent;
  stroke: $black;
  stroke-width: 14;

  @media (min-width: $large) {
    stroke-width: 16;
  }
}

.view {
  // position: relative; // Keeps SVG below content
  position: absolute;
  flex: 1;
  // z-index: 2;
  width: 100%;
  height: calc(100% - var(--active-panel-bar-height));
  margin-top: var(--active-panel-bar-height);
  overflow: auto;
  transition: background-color $delay ease;
  -webkit-overflow-scrolling: touch;

  &:global(.slider-zoomed) {
    overflow: hidden;
  }

  :global(.is-active) & {
    background-color: $grey-fill;
    transition: background-color $delay ease $delay * 2;
  }

  .tool & {
    height: calc(100% - var(--tool-active-panel-bar-height));
    margin-top: var(--tool-active-panel-bar-height);
  }

  @media (min-width: $large) {
    width: calc(100% - var(--active-panel-bar-height));
    height: 100%;
    margin-top: 0;
    margin-left: var(--active-panel-bar-height);

    .tool & {
      // width: calc(100% - var(--tool-active-panel-bar-height));
      height: 100%;
      margin-top: 0;
      // margin-left: var(--tool-active-panel-bar-height);
    }
  }
}

:global(.in-transition) {
  .view {
    position: absolute;
    overflow: hidden;
  }
}

// .tool {
//   background-color: $yellow;
// }

// .patterns {
//   background-color: $blue;
// }

// .projects {
//   background-color: $red;
// }
