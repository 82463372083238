
          @import '@/styles/globals.scss';
        



















































































.component {
  @include border-regular(top);

  position: relative;
  z-index: 1;
  margin-bottom: $blank-line * 1.5;
}

.wrapper {
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms ease, visibility 150ms ease;

  .is-open & {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $large) {
    // NOTE: override mobile styles if nav was closed
    // opacity: 1;
    // visibility: visible;
    // transition: none;
    @include border-regular(bottom);

    padding-top: $gutter / 2;
  }
}
